import React, { useState } from 'react'
import FormBtn from './kit/buttons/form-btn/FormBtn'
import FormInput from './kit/inputs/form-input/FormInput'
import logo from './kit/images/logo.svg'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {
	BASE_URL,
	ACCESS_TOKEN,
	PROXY,
	PROXY_URL,
} from './../settings.json'
import axios from 'axios'

const LoginView = () => {
	const MySwal = withReactContent(Swal)

	const [CodeWord, setCodeWord] = useState('')
	const [ShortNumber, setShortNumber] = useState('')
	// const [Language, setLanguage] = React.useState('ru')

	// const handleLangChange = event => {
	// 	setLanguage(event.target.value)
	// }

	const handleCodewordChange = event => {
		setCodeWord(event.target.value)
	}

	const handleShortNumberChange = event => {
		setShortNumber(event.target.value)
	}

	// function ChangeFavicon() {
	// 	let faviconLink = document.querySelectorAll(
	// 		'link[rel="icon"], link[rel="shortcut icon"]'
	// 	)
	// 	faviconLink.forEach(function (element) {
	// 		element.setAttribute('href', `${FAVICON}`)
	// 	})
	// }

	// function ChangePageTitle() {
	// 	let pageTitle = document.querySelector('title')
	// 	pageTitle.textContent = CLINIC_NAME
	// }

	// ChangeFavicon()
	// ChangePageTitle()

	function base64ToArrayBuffer(base64) {
		var binaryString = window.atob(base64)
		var binaryLen = binaryString.length
		var bytes = new Uint8Array(binaryLen)
		for (var i = 0; i < binaryLen; i++) {
			var ascii = binaryString.charCodeAt(i)
			bytes[i] = ascii
		}
		return bytes
	}

	function getMobileOperatingSystem(reportName, byte) {
		let userAgent = navigator.userAgent || navigator.vendor || window.opera
		let blob = new Blob([byte], { type: 'application/pdf' })
		let fileName = reportName
		let link = document.createElement('a')

		if (/android/i.test(userAgent)) {
			let fileURL = URL.createObjectURL(blob)
			link.href = fileURL
			link.download = fileName
			document.body.appendChild(link)
			link.click()
			window.open(fileURL)
			document.body.removeChild(link)
		} else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
			link.href = URL.createObjectURL(blob)
			link.target = '_blank'
			link.download = fileName
			document.body.appendChild(link)
			link.click()
		} else {
			link.href = URL.createObjectURL(blob)
			link.download = fileName
			document.body.appendChild(link)
			let newWindow = window.open('/')
			setTimeout(() => {
				newWindow.location = URL.createObjectURL(blob)
			}, 500)
			link.click()
			document.body.removeChild(link)
		}
	}

	function GetPDFResult(e) {
		e.preventDefault()
		if (!CodeWord || !ShortNumber) {
			MySwal.fire({
				icon: 'error',
				title: 'Ошибка',
				text: `Введите ${!CodeWord ? 'кодовое слово' : ''} ${
					!CodeWord && !ShortNumber ? 'и' : ''
				} ${!ShortNumber ? 'номер заказа' : ''}`,
				showConfirmButton: true,
				confirmButtonColor: '#ef7f1a',
				iconColor: '#ef7f1a',
			})
		} else {
			MySwal.fire({
				title: 'Ищем Ваш результат',
				text: 'Пожалуйста, подождите.....',
				showConfirmButton: false,
			})
			axios({
				method: 'GET',
				url: `${PROXY === true ? PROXY_URL : ''}${BASE_URL}GetPdfByPhp`,
				headers: {
					'Content-Type': 'application/json\r\n',
				},
				params: {
					access_token: ACCESS_TOKEN,
					codeword: CodeWord,
					shortNumber: ShortNumber,
					// language: Language,
				},
			})
				.then(({ data }) => {
					console.log(data)
					MySwal.close()
					MySwal.fire({
						icon: 'success',
						title: 'Найдено!',
						text: 'Перенаправляем.....',
						showConfirmButton: false,
						timer: 3000,
					})
					setTimeout(() => {
						console.log(data)
						var sampleArr = base64ToArrayBuffer(data)
						getMobileOperatingSystem(`Результат`, sampleArr)
					}, 1000)
				})
				.catch(e => {
					MySwal.fire({
						icon: 'error',
						title: 'Не найдено!',
						text: 'Возможно вы ввели неправильное кодовое слово или номер заказа!',
						showConfirmButton: true,
						confirmButtonColor: '#ef7f1a',
						iconColor: '#ef7f1a',
					})
				})
		}
	}

	return (
		<div className='login-view'>
			<div className='login-view-container'>
				<form className='login-view-form'>
					<div className='login-view-logo'>
						<h1 className='login-view-logo__title'>
							<img src={logo} alt='' />
							{/* РУМЕД */}
						</h1>
						{/* <p className='login-view-logo__subtitle'>
							лікувально-діагностичних центрів
						</p> */}
					</div>
					<FormInput
						label='Номер заказа'
						onChange={handleShortNumberChange}
					/>
					<FormInput
						label='Кодовое слово с квитанции'
						onChange={handleCodewordChange}
					/>
					{/* <FormSelect
						onChange={handleLangChange}
						Language={Language}
						LANGUAGES={LANGUAGES}
					/> */}
					<FormBtn onClick={GetPDFResult} />
				</form>
			</div>
			{/* <div className='login-view-bg'>
				<img src={bg} alt='' />
			</div> */}
		</div>
	)
}

export default LoginView
